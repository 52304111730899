import Playground from './components/playground/playground';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToggleButton } from '@mui/material';
import { useCallback, useState } from 'react';

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const [isThemeDark, setIsThemeDark] = useState(true);

  const handleThemeToggle = useCallback(() => {
    setIsThemeDark(th => !th);
  }, []);

  return (
    <ThemeProvider theme={isThemeDark ? darkTheme : lightTheme}>
      <ToggleButton value={isThemeDark} onChange={handleThemeToggle} style={{ color: isThemeDark ? '#fff' : '#000', top: 5, right: 5, position: 'absolute' }}>Theme</ToggleButton>
      <CssBaseline />
      <Playground theme={isThemeDark ? 'dark' : 'light'} />
    </ThemeProvider>

  );
}

export default App;
