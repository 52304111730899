import { Button, Collapse, Stack, TextField } from "@mui/material";
import { getErrors } from "./playground/playground";

type Props = {
  text: string;
  errors: string[];
  onChange: (t: string, es: string[]) => void;
  collapse: boolean;
  onRemove: () => void;
};

export default function ToolUse({
  text,
  errors,
  onChange,
  collapse,
  onRemove,
}: Props) {
  function handleChangeText(nextText: string) {
    onChange(nextText, getErrors("tool-use", nextText));
  }

  return (
    <Collapse in={collapse} timeout="auto" unmountOnExit>
      <Stack direction={"row"}>
        <TextField
          id="outlined-basic"
          fullWidth
          value={text}
          multiline
          label="assistant / tool-use"
          variant="outlined"
          onChange={(event) => handleChangeText(event.target.value)}
          error={errors.length > 0}
          helperText={errors.join("\n")}
        />
        <Button style={{ color: "#fff" }} onClick={onRemove}>
          &#120;
        </Button>
      </Stack>
    </Collapse>
  );
}
