import { Message } from "../types/message";

const toolUseTemplate: Message = {
    role: "assistant",
    content: null,
    tool_calls: [
        {
            id: "<id here>",
            type: "function",
            function: {
                name: "<function name here>",
                arguments: "{}"
            }
        }
    ]
};

export default toolUseTemplate;