import { Collapse, TextField } from "@mui/material";
import { getErrors } from "./playground/playground";

type Props = {
  text: string;
  errors: string[];
  onChange: (t: string, es: string[]) => void;
  collapse: boolean;
};

export default function Tools({ text, errors, onChange, collapse }: Props) {
  function handleChangeText(nextText: string) {
    onChange(nextText, getErrors("tools", nextText));
  }

  return (
    <Collapse in={collapse} timeout="auto" unmountOnExit>
      <TextField
        id="outlined-basic"
        fullWidth
        value={text}
        multiline
        label="tools"
        variant="outlined"
        onChange={(event) => handleChangeText(event.target.value)}
        error={errors.length > 0}
        helperText={errors.join("\n")}
      />
    </Collapse>
  );
}
