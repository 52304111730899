import MistralClient from "@mistralai/mistralai";

type ClientProps = { apiKey: string | undefined };

function getMistralAiClient(props?: ClientProps) {
    const { apiKey } = props ?? {};
    const client = new MistralClient(apiKey || process.env.REACT_APP_MISTRALAI_API_KEY);
    return client;
}

export default getMistralAiClient;