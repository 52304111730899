import { ApiCall } from "../types/api-call";
import getOpenAiClient from "./getOpenAiClient";
import getMistralAiClient from "./getMistralAiClient";
import { OPENAI, MISTRALAI } from '../utils/constants';

async function callWithOpenAI({ tools, apiKey, model, baseURL, messages }: ApiCall) {
    const client = getOpenAiClient({ apiKey, baseURL })

    const payloadMessages = [];

    // Stringify the message content
    for (let message of messages) {
        if (message.role === 'tool') {
            payloadMessages.push({ ...message, content: JSON.stringify(message.content) });
        }
        else {
            payloadMessages.push({ ...message });
        }
    }

    const payload = {
        model: model,
        messages: payloadMessages
    } as any;

    if (tools.length > 0) {
        payload.tools = tools;
        payload.tool_choice = "auto";
    }

    let response = await client.chat.completions.create(payload);

    return response.choices[0];
}

async function callWithMistralAI({ tools, apiKey, model, messages }: ApiCall) {
    const client = getMistralAiClient({ apiKey: apiKey || process.env.REACT_APP_MISTRALAI_API_KEY });

    const payloadMessages = [];

    // Stringify the message content
    for (let message of messages) {
        if (message.role === 'tool') {
            payloadMessages.push({ ...message, content: JSON.stringify(message.content) });
        }
        else {
            payloadMessages.push({ ...message });
        }
    }

    const payload = {
        model: model || process.env.REACT_APP_MISTRALAI_MODEL,
        messages: payloadMessages
    } as any;

    if (tools.length > 0) {
        payload.tools = tools;
        payload.tool_choice = "auto";
    }

    const chatStreamResponse = await client.chat(payload);

    return chatStreamResponse.choices[0]
}

export async function callApi({ tools, apiKey, model, baseURL, messages, providerPackage }: ApiCall) {

    if (providerPackage === OPENAI) {
        return callWithOpenAI({ tools, apiKey, model, baseURL, messages });
    }

    if (providerPackage === MISTRALAI) {
        return callWithMistralAI({ tools, apiKey, model, baseURL, messages });
    }
}