import ipynbTemplate from '../templates/ipynb-template.json';
import { Message } from '../types/message';


function getRole(message: Message) {
    if (message.role === 'system') {
        return 'system';
    } else if (message.role === 'assistant' && !message.tool_calls) {
        return 'assistant';
    } else if (message.role === 'assistant' && message.tool_calls) {
        return 'tool_use';
    } else if (message.role === 'tool') {
        return 'tool_output';
    } else if (message.role === 'user') {
        return 'user';
    }
}

function getToolUse(toolCalls: any) {
    const tool_use = {
        "tool_use": [] as any
    }

    for (let toolCall of toolCalls) {
        const oneToolUse = {
            "function_name": "<function_name>",
            "arguments": {
                "<argument_name>": "<argument_value>"
            }
        }

        oneToolUse.function_name = toolCall.function.name;
        oneToolUse.arguments = JSON.parse(toolCall.function.arguments);

        tool_use.tool_use.push(oneToolUse as any);
    }

    return tool_use;
}

function getToolOutput(toolOutput: any) {

    try {
        return JSON.parse(toolOutput);
    }
    catch (e) {
        return toolOutput;
    }

}

function createIpynb(messages: Message[], tools: any) {

    for (let i = 1; i < messages.length; i++) {
        const toolOutputs = [];
        if (messages[i - 1].role === 'tool') {
            // console.log(messages[i - 1].content);
            toolOutputs.push(getToolOutput(messages[i - 1].content));

            let j = i;
            while (j < messages.length && messages[j].role === "tool") {
                toolOutputs.push(getToolOutput(messages[j].content));
                j++;
            }

            messages[i - 1].content = JSON.stringify(toolOutputs);
            messages = messages.slice(0, i).concat(messages.slice(j));
        }

    }

    messages = JSON.parse(JSON.stringify(messages));

    const ipynbTemplateResult = JSON.parse(JSON.stringify(ipynbTemplate));

    let counter = 0;

    for (const message of messages) {
        const role = getRole(message)

        let cell = {
            cell_type: 'markdown',
            metadata: { tags: [role, (counter++).toString()] },
            source: []
        } as any;

        if (role === 'system' || role === 'assistant' || role === 'user') {
            cell.source = `**[${role}]**\n\n---\n\n${[message.content]}`.split('\n').map((line: string) => line + '\n');
        }
        else if (role === 'tool_use') {
            cell.source = `**[${role}]**\n\n---\n\n\`\`\`json\n${[JSON.stringify(getToolUse(message.tool_calls), undefined, 2)]}\n\`\`\``.split('\n').map((line: string) => line + '\n');
        }
        else if (role === 'tool_output') {
            cell.source = `**[${role}]**\n\n---\n\n\`\`\`json\n${[JSON.stringify(getToolOutput(message.content), undefined, 2)]}\n\`\`\``.split('\n').map((line: string) => line + '\n');
        }
        ipynbTemplateResult.cells.push(cell as any);
    }

    const toolsCell = {
        cell_type: 'markdown',
        metadata: { tags: ['tools', '0'] },
        source: `**[tools]**\n\n---\n\n\`\`\`json\n${[JSON.stringify(tools, undefined, 2)]}\n\`\`\``.split('\n').map((line: string) => line + '\n'),
    };

    ipynbTemplateResult.cells = [ipynbTemplateResult.cells[0], toolsCell, ...ipynbTemplateResult.cells.slice(1)]

    return ipynbTemplateResult;
}

export default createIpynb