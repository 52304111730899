import OpenAI from "openai";

type ClientProps = { apiKey: string, baseURL: string | undefined };

function getOpenAiClient(props?: ClientProps) {
    const { apiKey, baseURL } = props ?? {};

    const client = new OpenAI({
        apiKey: apiKey || (
            !baseURL
                ? process.env.REACT_APP_OPENAI_API_KEY
                :
                baseURL === process.env.REACT_APP_TOGETHERAI_BASE_URL
                    ? process.env.REACT_APP_TOGETHERAI_API_KEY
                    : ''),
        baseURL: baseURL,
        dangerouslyAllowBrowser: true
    });

    return client;
}

export default getOpenAiClient;