import { useCallback, useRef, useState } from "react";
import { Button, Collapse, Stack, TextField } from "@mui/material";
import MuiMarkdown from "mui-markdown";

type Props = {
  enableMarkdownPreview: boolean;
  collapse: boolean;
  label: string;
  text: string;
  onChange: (t: string) => void;
  removable?: boolean;
  onRemove?: () => void;
};

export default function MarkdownEditor({
  enableMarkdownPreview,
  collapse,
  removable,
  label,
  text,
  onChange,
  onRemove,
}: Props) {
  const [isShowingPreview, setIsShowingPreview] = useState<boolean>(true),
    textInputRef = useRef<null | HTMLInputElement>(null),
    handleTextInputFocus = useCallback(() => {
      const moveCursor = () => {
        const textInput = textInputRef.current;
        // console.log({ textInput });
        if (textInput) {
          const textAreas = Array.from(textInput.querySelectorAll("textarea"));
          //   console.log({ textAreas });
          if (textAreas.length > 0)
            textAreas.forEach(
              (ta) => (ta.selectionStart = ta.selectionEnd = text.length)
            );
        }
      };
      moveCursor();
      setTimeout(moveCursor, 0);
    }, [text]);
  if (enableMarkdownPreview) {
    if (isShowingPreview)
      return (
        <div onClick={() => setIsShowingPreview(false)}>
          <Collapse in={collapse} timeout="auto" unmountOnExit>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: removable ? "1fr 64px" : "1fr",
              }}
            >
              <fieldset style={{ flex: 1 }}>
                <legend>{label}</legend>
                {text.length === 0 ? (
                  <p style={{ fontStyle: "italic" }}>Empty (click to edit)</p>
                ) : (
                  <MuiMarkdown>{text}</MuiMarkdown>
                )}
              </fieldset>
              {removable && (
                <Button style={{ color: "#fff" }} onClick={onRemove}>
                  &#120;
                </Button>
              )}
            </div>
          </Collapse>
        </div>
      );
    else
      return (
        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: removable ? "1fr 64px" : "1fr",
            }}
          >
            <TextField
              ref={textInputRef}
              id="outlined-basic"
              fullWidth
              value={text}
              multiline
              label={label}
              variant="outlined"
              onChange={(event) => onChange(event.target.value)}
              onBlur={() => setIsShowingPreview(true)}
              autoFocus={true}
              onKeyDown={({ key }) =>
                key === "Escape" && setIsShowingPreview(true)
              }
              onFocusCapture={handleTextInputFocus}
              style={{ flex: 1 }}
            />
            {removable && (
              <Button style={{ color: "#fff" }} onClick={onRemove}>
                &#120;
              </Button>
            )}
          </div>
        </Collapse>
      );
  } else {
    return (
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        <Stack direction={"row"}>
          <TextField
            id="outlined-basic"
            fullWidth
            value={text}
            multiline
            label={label}
            variant="outlined"
            onChange={(event) => onChange(event.target.value)}
          />
          {removable && (
            <Button style={{ color: "#fff" }} onClick={onRemove}>
              &#120;
            </Button>
          )}
        </Stack>
      </Collapse>
    );
  }
}
