import Button from '@mui/material/Button';

const ButtonMailTo = ({ email, subject, body, label }: {email: string, subject: string, body: string, label: string }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
      style={{alignSelf: 'center', fontSize:'0.4em'}}
    >
      {label}
    </Button>
  );
};

export default ButtonMailTo;
