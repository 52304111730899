import { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import Stack from "@mui/material/Stack";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import createClone from "rfdc";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';


import { Message, tool_call } from "../../types/message";

import "react-json-view-lite/dist/index.css";
import "./playground.css";

import { callApi } from "../../utils/call-api";
import toolUseTemplate from '../../templates/tool-use-template';
import toolOutputTemplate from '../../templates/tool-output-template';
import { OPENAI, MISTRALAI } from '../../utils/constants';
import styled from '@emotion/styled';
import createIpynb from '../../utils/create-ipynb';
import ToolUse from "../tool-use";
import ToolOutput from "../tool-output";
import Tools from "../tools";
import MarkdownEditor from "../markdown-editor";
import ButtonMailTo from "../button-mail-to";

const clone = createClone();

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Playground({ theme }: { theme: string }) {
  const [baseURL, setBaseURL] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [tools, setTools] = useState([] as any[]);
  const [toolsText, setToolsText] = useState<string>(() =>
    JSON.stringify(tools)
  );
  const [toolsErrors, setToolsErrors] = useState<string[]>([]);
  const [model, setModel] = useState('');
  const [result, setResult] = useState({});
  const [calling, setCalling] = useState(false);
  const [error, setError] = useState(undefined);
  const [toggleMd, setToggleMd] = useState(false);
  const [providerPackage, setTogglePackage] = useState(MISTRALAI);
  const [collapseJsonBox, setCollapseJsonBox] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [messages, setMessages] = useState([
    { role: "system", content: "" },
    { role: "user", content: "" },
  ] as Message[]);
  const [texts, setTexts] = useState<string[]>(() =>
    messages.map((m) => getText(m))
  );
  const [errors, setErrors] = useState(() =>
    texts.map((text, index) => getErrors(getType(messages[index]), text))
  );
  const [fileHref, setFileHref] = useState("");
  const downloadBtn = useRef();

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;
  const textFieldStyle =
    theme === "dark"
      ? { width: `${400}px`, background: "#000", color: "#fff" }
      : { width: `${400}px`, background: "#fff", color: "#000" };

  const handleToolsChange = useCallback((e: any) => {
    setTools(JSON.parse(e.target.value || "[]"));
  }, []);

  const handleBaseURLChange = useCallback((e: any) => {
    setBaseURL(e.target.value);
  }, []);

  const handleApiKeyChange = useCallback((e: any) => {
    setApiKey(e.target.value);
  }, []);

  const handleModelChange = useCallback((e: any) => {
    setModel(e?.target?.value);
  }, []);

  const handleExpandClick = useCallback(
    (e: any) => {
      setExpanded(!expanded);
    },
    [expanded]
  );

  const handlePackageToggleChange = useCallback(
    (e: any) => {
      setTogglePackage((togglePackage) =>
        togglePackage === OPENAI ? MISTRALAI : OPENAI
      );
    },
    []
  );

  const handleMdToggleChange = useCallback(
    (e: any) => {
      setToggleMd(!toggleMd);
    },
    [toggleMd]
  );

  const handleCall = useCallback(
    async (e: any) => {
      setCalling(true);
      const result = await callApi({
        tools,
        baseURL,
        apiKey,
        model,
        messages,
        providerPackage,
      });
      setCalling(false);
      setResult(result as any);
    },
    [tools, baseURL, apiKey, model, messages, providerPackage]
  );

  const handleExportImport = useCallback(
    async (event: any) => {
      setAnchor(anchor ? null : event.currentTarget);
    },
    [anchor]
  );

  const handleJsonBoxClick = useCallback(() => {
    setCollapseJsonBox((x) => !x);
  }, []);

  const handleAddUserClick = useCallback(() => {
    const userMessage = { role: "user", content: "" };
    setMessages([...messages, userMessage] as any);
    setTexts((p) => [...p, getText(userMessage)]);
    setErrors((p) => [
      ...p,
      getErrors(getType(userMessage), getText(userMessage)),
    ]);
  }, [messages]);

  const handleAddAssistantClick = useCallback(() => {
    const assistantMessage = { role: "assistant", content: "" };
    setMessages([...messages, assistantMessage] as any);
    setTexts((p) => [...p, getText(assistantMessage)]);
    setErrors((p) => [
      ...p,
      getErrors(getType(assistantMessage), getText(assistantMessage)),
    ]);
  }, [messages]);

  const handleAddToolUseClick = useCallback(() => {
    setMessages([...messages, toolUseTemplate] as any);
    setTexts((p) => [...p, getText(toolUseTemplate)]);
    setErrors((p) => [
      ...p,
      getErrors(getType(toolUseTemplate), getText(toolUseTemplate)),
    ]);
  }, [messages]);

  const handleAddToolOutputClick = useCallback(() => {
    const toolOutput = toolOutputTemplate();

    let idxToolCalls = messages.length - 1;
    for (; idxToolCalls >= 0; idxToolCalls--) {
      if (messages[idxToolCalls].tool_calls) {
        break;
      }
    }

    const howFar = messages.length - 1 - idxToolCalls;

    toolOutput.tool_call_id = String(
      messages[idxToolCalls].tool_calls?.[howFar].id
    );

    toolOutput.name = String(
      messages[messages.length - 1].tool_calls?.[0].function.name
    );
    setMessages([...messages, toolOutput] as any);
    setTexts((p) => [...p, getText(toolOutput)]);
    setErrors((p) => [
      ...p,
      getErrors(getType(toolOutput), getText(toolOutput)),
    ]);
  }, [messages]);

  const handleMessageChange = useCallback(
    (e: any, idx: number) => {
      try {
        if (messages[idx].role === "tool") {
          // Tool output is shown in raw format
          messages[idx] = e.target.value
            ? { ...messages[idx], content: JSON.parse(e.target.value) }
            : toolOutputTemplate();
        } else if (messages[idx].tool_calls) {
          // Tool use is shown in raw format
          messages[idx] = e.target.value
            ? JSON.parse(e.target.value)
            : toolUseTemplate;
        } else {
          messages[idx].content = e.target.value;
        }

        setMessages([...messages]);
      } catch (err) {
        setError(err as any);
      }
    },
    [messages]
  );

  const handleRemoveCellClick = useCallback(
    (idx: number) => {
      setMessages([...messages.slice(0, idx), ...messages.slice(idx + 1)]);
      setTexts([...texts.slice(0, idx), ...texts.slice(idx + 1)]);
      setErrors([...errors.slice(0, idx), ...errors.slice(idx + 1)]);
    },
    [messages, texts, errors]
  );

  const handleJsonDoubleClick = useCallback(
    (e: any) => {
      if ((result as any)?.message) {
        setMessages([...messages, (result as any).message]);
        setTexts((p) => [...p, getText((result as any).message)]);
        setErrors((p) => [
          ...p,
          getErrors(
            getType((result as any).message),
            getText((result as any).message)
          ),
        ]);
      } else {
        setError({ message: "Result is empty!" } as any);
      }
    },
    [messages, result]
  );

  const exportConvo = useCallback(() => {
    return JSON.stringify(
      {
        messages: messages,
        tools: tools,
      },
      undefined,
      2
    );
  }, [messages, tools]);

  const importConvo = useCallback(
    (e: any) => {
      try {
        const convo = JSON.parse(e.target.value);

        setMessages(convo.messages);
        setTools(convo.tools ?? convo.available_tools);
        setToolsText(JSON.stringify(convo.tools ?? convo.available_tools, undefined, 2));
        setTexts(convo.messages.map(getText));
        setErrors(
          convo.messages.map((m: Message) => getErrors(getType(m), getText(m)))
        );
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  useEffect(() => {
    if (providerPackage === MISTRALAI) {
      setBaseURL("");
      setModel('mistral-large-latest');
    }
    else {
      setModel('gpt-4o');
    }
  }, [providerPackage]);

  useEffect(() => {
    const notebook = createIpynb(clone(messages), tools);

    setFileHref(
      `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(notebook)
      )}`
    );
  }, [messages, tools]);

  useEffect(() => {
    const ajv = new Ajv();

    addFormats(ajv as any);

    const errors = [];

    for (let tool of tools) {
      try {
        if (tool.type !== "function") {
          errors.push(`Tool type ${tool.type} is not supported`);
        }

        if (tool.description) {
          errors.push(
            `Tool description should be as tool.function.description`
          );
        }

        // Tool function name should match the regex pattern /^[a-zA-Z0-9_]+{1, 64}$/
        if (tool.function.name.match(/^[a-zA-Z0-9_]{1,64}$/) === null) {
          errors.push(
            `Tool function type should match the regex pattern /^[a-zA-Z0-9_]{1, 64}$/`
          );
        }

        // Tool properties should also match the regex pattern /^[a-zA-Z0-9_]{1,64}$/
        Object.keys(tool.function.parameters.properties).forEach((key) => {
          if (key.match(/^[a-zA-Z0-9_]{1,64}$/) === null) {
            errors.push(
              `Tool '${tool.function.name}::${key}' properties should match the regex pattern /^[a-zA-Z0-9_]{1, 64}$/`
            );
          }
        });

        const anyTool = tool as any;
        ajv.compile(anyTool.function.parameters);
      } catch (err) {
        errors.push((err as any).message);
      }
    }

    if (errors.length > 0) {
      setToolsErrors([new Error(JSON.stringify(errors, undefined, 2)) as any]);
    }
  }, [tools]);

  return (
    <Stack direction={"column"} margin={10}>
      {error && (
        <Alert
          title="Error"
          className="alert-fixed"
          severity="error"
          onClose={() => setError(undefined)}
        >
          {(error as any).message}
        </Alert>
      )}
      <Stack spacing={5}>
        <Grid container justifyContent="center">
          <Box>
            <Typography>
            npm {MISTRALAI}
              <Switch
                checked={providerPackage === OPENAI}
                onChange={handlePackageToggleChange}
                inputProps={{ "aria-label": "switch" }}
              />
            npm {OPENAI}
            </Typography>
            <Typography>
              {"Editor"}
              <Switch
                checked={toggleMd}
                onChange={handleMdToggleChange}
                inputProps={{ "aria-label": "switch" }}
              />
              {"Markdown"}
            </Typography>
          </Box>
        </Grid>
        <Button
          variant="contained"
          onClick={handleCall}
          style={{ alignSelf: "center" }}
        >
          Call
        </Button>
        <Button
          ref={downloadBtn.current}
          href={fileHref}
          download={"taxonomy,number,domain,subdomain,misc.ipynb"}
          variant="contained"
        >
          {`Download Notebook`}
        </Button>
        <Stack direction={"row"} spacing={10}>
        <TextField
            InputProps={{ style: textFieldStyle }}
            label="BaseURL [optional]"
            value={baseURL}
            variant="outlined"
            onChange={handleBaseURLChange}
          />
          <TextField
            InputProps={{ style: textFieldStyle }}
            label="Model"
            value={model}
            variant="outlined"
            onChange={handleModelChange}
          />
          <TextField
            InputProps={{ style: textFieldStyle }}
            label="API Key"
            type="password"
            variant="outlined"
            onBlur={handleApiKeyChange}
          />
        </Stack>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <MarkdownEditor
          enableMarkdownPreview={toggleMd}
          collapse={expanded}
          label="system"
          text={messages[0].content?.toString?.() ?? ""}
          onChange={(nextText) =>
            handleMessageChange({ target: { value: nextText } }, 0)
          }
        />
        <Tools
          text={toolsText}
          errors={toolsErrors}
          collapse={expanded}
          onChange={(nextText: string, nextErrors: string[]) => {
            setToolsText(nextText);
            setToolsErrors(nextErrors);
            if (nextErrors.length === 0) {
              handleToolsChange({ target: { value: nextText } });
            } else {
              handleToolsChange({ target: { value: '[]' } });
            }
          }}
        />

        <MarkdownEditor
          enableMarkdownPreview={toggleMd}
          collapse={expanded}
          label={messages[1].role}
          text={messages[1].content as string}
          onChange={(nextText) =>
            handleMessageChange({ target: { value: nextText } }, 1)
          }
        />

        {messages.slice(2).map((message, idx) => {
          switch (getType(message)) {
            case "tool-use":
              return (
                <ToolUse
                  key={`message-stack-${idx + 2}`}
                  text={texts[2 + idx]}
                  errors={errors[2 + idx]}
                  onChange={(nextText: string, nextErrors: string[]) => {
                    setTexts((ts) => [
                      ...ts.slice(0, 2 + idx),
                      nextText,
                      ...ts.slice(2 + idx + 1),
                    ]);
                    setErrors((es) => [
                      ...es.slice(0, 2 + idx),
                      nextErrors,
                      ...es.slice(2 + idx + 1),
                    ]);
                    if (nextErrors.length === 0) {
                      const _nextText = JSON.stringify({
                        ...message,
                        tool_calls: (JSON.parse(nextText) as tool_call[]).map(
                          (tc) => ({
                            ...tc,
                            function: {
                              ...tc.function,
                              arguments: JSON.stringify(tc.function.arguments),
                            },
                          })
                        ),
                      });
                      handleMessageChange(
                        { target: { value: _nextText } },
                        idx + 2
                      );
                    } else {
                      handleMessageChange(
                        { target: { value: JSON.stringify(toolUseTemplate) } },
                        idx + 2
                      );
                    }
                  }}
                  collapse={expanded || idx === messages.length - 3}
                  onRemove={() => handleRemoveCellClick(idx + 2)}
                />
              );

            case "tool-output":
              return (
                <ToolOutput
                  key={`message-stack-${idx + 2}`}
                  text={texts[2 + idx]}
                  errors={errors[2 + idx]}
                  onChange={(nextText: string, nextErrors: string[]) => {
                    setTexts((ts) => [
                      ...ts.slice(0, 2 + idx),
                      nextText,
                      ...ts.slice(2 + idx + 1),
                    ]);
                    setErrors((es) => [
                      ...es.slice(0, 2 + idx),
                      nextErrors,
                      ...es.slice(2 + idx + 1),
                    ]);
                    if (nextErrors.length === 0) {
                      handleMessageChange(
                        { target: { value: nextText } },
                        idx + 2
                      );
                    } else {
                      handleMessageChange(
                        { target: { value: JSON.stringify(toolOutputTemplate().content) } },
                        idx + 2
                      );
                    }
                  }}
                  collapse={expanded || idx === messages.length - 3}
                  onRemove={() => handleRemoveCellClick(idx + 2)}
                />
              );

            case "user":
            case "assistant":
              return (
                <MarkdownEditor
                  enableMarkdownPreview={toggleMd}
                  collapse={expanded || idx === messages.length - 3}
                  label={message.role}
                  text={getVisibleMessage(message)}
                  onChange={(nextText) =>
                    handleMessageChange(
                      { target: { value: nextText } },
                      idx + 2
                    )
                  }
                  removable={true}
                  onRemove={() => handleRemoveCellClick(idx + 2)}
                />
              );

            default:
              return (
                <Collapse
                  key={`message-stack-${idx + 2}`}
                  in={expanded || idx === messages.length - 3}
                  timeout="auto"
                  unmountOnExit
                >
                  <Stack direction={"row"}>
                    <TextField
                      key={`message-text-${idx + 2}`}
                      id="outlined-basic"
                      fullWidth
                      value={getVisibleMessage(message)}
                      multiline
                      label={message.role}
                      variant="outlined"
                      onChange={(e) => handleMessageChange(e, idx + 2)}
                    />
                    <Button
                      style={{ color: "#fff" }}
                      onClick={() => handleRemoveCellClick(idx + 2)}
                    >
                      &#120;
                    </Button>
                  </Stack>
                </Collapse>
              );
          }
        })}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
        <FormHelperText variant="outlined">
          tool_calls content should be valid JSON and pasted as a whole.
        </FormHelperText>
        <Stack
          direction={"row"}
          gap={10}
          alignSelf={"center"}
          style={{ border: "1px solid white", padding: 5 }}
        >
          <InputLabel style={{ alignSelf: "center" }}>
            {" "}
            Add a New Cell{" "}
          </InputLabel>
          <Button
            variant="contained"
            onClick={handleAddUserClick}
            style={{ alignSelf: "center" }}
          >
            User
          </Button>
          <Button
            variant="contained"
            onClick={handleAddAssistantClick}
            style={{ alignSelf: "center" }}
          >
            Assistant
          </Button>
          <Button
            variant="contained"
            onClick={handleAddToolUseClick}
            style={{ alignSelf: "center" }}
          >
            Tool Use
          </Button>
          <Button
            variant="contained"
            onClick={handleAddToolOutputClick}
            style={{ alignSelf: "center" }}
          >
            Tool Output
          </Button>
        </Stack>
        <Button
          variant="contained"
          onClick={handleCall}
          style={{ alignSelf: "center" }}
        >
          Call
        </Button>
        <Button
          variant="contained"
          onClick={handleJsonDoubleClick}
          style={{ alignSelf: "center", fontSize: "0.5em" }}
        >
          Append AI Message to Convo
        </Button>
        <Button
          variant="contained"
          onClick={handleExportImport}
          style={{ alignSelf: "center" }}
        >
          Export/Import
        </Button>
        <BasePopup id={id} open={open} anchor={anchor}>
          <TextField
            style={{ background: "#000", color: "#FFF", width: "60vw" }}
            fullWidth
            multiline
            value={exportConvo()}
            onChange={importConvo}
          ></TextField>
        </BasePopup>
        {calling ? (
          <Grid container justifyContent="center">
            <CircularProgress></CircularProgress>
          </Grid>
        ) : (
          <Box
            onClick={handleJsonBoxClick}
            style={{ maxHeight: collapseJsonBox ? 400 : 800, overflow: "auto" }}
            component="div"
          >
            <JsonView
              data={result}
              shouldExpandNode={allExpanded}
              style={theme === "dark" ? darkStyles : defaultStyles}
            />
          </Box>
        )}
        <Box
          component="div"
          sx={{
            display: "inline",
            border: "1px dashed green",
            minHeight: "100px",
          }}
        >
          <pre
            onDoubleClick={handleJsonDoubleClick}
            style={{ whiteSpace: "pre-wrap", textAlign: "left", padding: 10 }}
          >
            {JSON.stringify(result, null, 2)}
          </pre>
        </Box>
        <ButtonMailTo email="reego.software+feedback4toolcallingai@gmail.com" subject="ToolCallingAI Feedback" body="Any experience feedback or feature request is welcome!" label="Feedback Mail to"/>
      </Stack>
    </Stack>
  );
}

export default Playground;

function getVisibleMessage(message: Message): string {
  // If tool calls then it's in raw format
  if (message.tool_calls) {
    return JSON.stringify(message, undefined, 2);
  }

  if (["user", "assistant"].includes(message.role)) {
    return message.content as string;
  }

  // Output
  if (message.role === "tool") {
    return JSON.stringify(message, undefined, 2);
  }

  return "";
}

function getType(message: Message): string {
  if (message.tool_calls) return "tool-use";
  else if (/user/.test(message.role)) return "user";
  else if (/assistant/.test(message.role)) return "assistant";
  else if (message.role === "tool") return "tool-output";
  else return "unknown";
}

function getText(message: Message): string {
  // If tool calls then it's in raw format
  if (message.tool_calls) {
    const toolCalls = (message.tool_calls as tool_call[]).map((tc) => ({
      ...tc,
      function: {
        ...tc.function,
        arguments: JSON.parse(tc.function.arguments),
      },
    }));
    return JSON.stringify(toolCalls, undefined, 2);
  }

  if (["user", "assistant"].includes(message.role)) {
    return message.content as string;
  }

  // Output
  if (message.role === "tool") {
    return JSON.stringify(message.content, undefined, 2);
  }

  return "";
}

export function getErrors(type: string, text: string): string[] {
  switch (type) {
    case "tool-use":
      try {
        (JSON.parse(text) as tool_call[]).map((tc) => ({
          ...tc,
          function: {
            ...tc.function,
            arguments: JSON.stringify(tc.function.arguments),
          },
        }));
        return [];
      } catch (error: any) {
        console.error(error);
        return [error?.message ?? "Unknown error"];
      }

    case "tools":
    case "tool-output":
      try {
        JSON.parse(text);
        return [];
      } catch (error: any) {
        console.error(error);
        return [error?.message ?? "Unknown error"];
      }

    default:
      return [];
  }
}
